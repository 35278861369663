<template>
	<nav class="navbar navbar-vertical navbar-expand-lg">
	    <div id="navbarVerticalCollapse" class="collapse navbar-collapse">
	        <div class="navbar-vertical-content">
	        	<ul id="navbarVerticalNav" class="navbar-nav flex-column">

	                <div class="nav-item-wrapper" v-if="$store.state.user.data.permissions.includes('admin')">
					    <router-link 
					    	class="nav-link label-1" 
					    	:to="{name: 'Home'}" 
					    	:class="{'active': ['Home'].includes(this.$route.name)}">
					        <div class="d-flex align-items-center">
					            <span class="nav-link-icon"><span class="fa-regular fa-house text-700 fs--1 form-icon"></span></span>
					            <span class="nav-link-text-wrapper"><span class="nav-link-text">Главная</span></span>
					        </div>
					    </router-link>

					    <hr/>
					</div>


					<li class="nav-item" v-if="$store.state.user.data.permissions.includes('admin') || $store.state.user.data.permissions.includes('admin.users')">
	                    <div class="nav-item-wrapper">
	                        <a 
	                        	:aria-expanded="this.$route.name.includes('Users') ? 'true' : 'false'" 
	                        	role="button" 
	                        	class="nav-link dropdown-indicator label-1">
	                            <div class="d-flex align-items-center">
	                                <div class="dropdown-indicator-icon"><span class="fa fa-caret-right"></span></div>
	                                <span class="nav-link-icon"><span class="fa-regular fa-users text-700 fs--1 form-icon"></span></span>
	                                <span class="nav-link-text">
	                                	Пользователи
	                                	<span class="badge badge-casino badge-casino-warning px-1" v-if="$store.state.notifications.kyc">
	                                		{{ $store.state.notifications.kyc }}
	                                	</span>
	                                </span>
	                            </div>
	                        </a>
	                        <div class="parent-wrapper label-1">
	                            <ul class="nav collapse parent" :class="{'show': this.$route.name.includes('Users')}">
	                            	<li class="collapsed-nav-item-title d-none">
	                            		Пользователи
	                            		<span class="badge badge-casino badge-casino-warning px-1" v-if="$store.state.notifications.kyc">
	                            			{{ $store.state.notifications.kyc }}
	                            		</span>
	                            	</li>
	                                <li class="nav-item">
	                                    <router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'UsersItems'}"
	                                    	:class="{'active': ['UsersItems', 'UsersItem'].includes(this.$route.name)}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">Список</span></div>
	                                    </router-link >
	                                </li>
	                                <li class="nav-item">
	                                    <router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'UsersStatusItems'}"
	                                    	:class="{'active': ['UsersStatusItems', 'UsersStatusItem'].includes(this.$route.name)}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">Статусы</span></div>
	                                    </router-link >
	                                </li>
	                                <li class="nav-item">
	                                    <router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'UsersKycItems'}"
	                                    	:class="{'active': ['UsersKycItems', 'UsersKycItem'].includes(this.$route.name)}">
	                                        <div class="d-flex align-items-center">
	                                        	<span class="nav-link-text">
	                                        		KYC верификация
	                                        		<span class="badge badge-casino badge-casino-warning px-1" v-if="$store.state.notifications.kyc">
	                                        			{{ $store.state.notifications.kyc }}
	                                        		</span>
	                                        	</span>
	                                        </div>
	                                    </router-link >
	                                </li>
	                                <li class="nav-item">
	                                    <router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'UsersLogs'}"
	                                    	:class="{'active': ['UsersLogs'].includes(this.$route.name)}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">Логи активности</span></div>
	                                    </router-link >
	                                </li>
	                                <li class="nav-item">
	                                    <router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'UsersSegmentation'}"
	                                    	:class="{'active': ['UsersSegmentation'].includes(this.$route.name)}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">Сегментация</span></div>
	                                    </router-link >
	                                </li>
	                            </ul>
	                        </div>
	                    </div>
	                </li>

	                <li class="nav-item" v-if="$store.state.user.data.permissions.includes('admin') || $store.state.user.data.permissions.includes('admin.tournaments')">
	                    <div class="nav-item-wrapper">
	                        <a 
	                        	:aria-expanded="this.$route.name.includes('Tournaments') ? 'true' : 'false'" 
	                        	role="button" 
	                        	class="nav-link dropdown-indicator label-1">
	                            <div class="d-flex align-items-center">
	                                <div class="dropdown-indicator-icon"><span class="fa fa-caret-right"></span></div>
	                                <span class="nav-link-icon"><span class="fa-regular fa-trophy text-700 fs--1 form-icon"></span></span>
	                                <span class="nav-link-text">Турниры</span>
	                            </div>
	                        </a>
	                        <div class="parent-wrapper label-1">
	                            <ul class="nav collapse parent" :class="{'show': this.$route.name.includes('Tournaments')}">
	                            	<li class="collapsed-nav-item-title d-none">Турниры</li>
	                                <li class="nav-item">
	                                    <router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'TournamentsItems', params: {status: 'active'}}"
	                                    	:class="{'active': ['TournamentsItems'].includes(this.$route.name) && this.$route.params.status == 'active'}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">Активные</span></div>
	                                    </router-link>
	                                </li>
	                                <li class="nav-item">
	                                    <router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'TournamentsItems', params: {status: 'past'}}"
	                                    	:class="{'active': ['TournamentsItems'].includes(this.$route.name) && this.$route.params.status == 'past'}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">Прошедшие</span></div>
	                                    </router-link>
	                                </li>
	                            </ul>
	                        </div>
	                    </div>
	                </li>

	                <li class="nav-item" v-if="$store.state.user.data.permissions.includes('admin') || $store.state.user.data.permissions.includes('admin.promotions') || $store.state.user.data.permissions.includes('admin.promocodes')">
	                    <div class="nav-item-wrapper">
	                        <a 
	                        	:aria-expanded="this.$route.name.includes('Promotions') || this.$route.name.includes('Promocodes') ? 'true' : 'false'" 
	                        	role="button" 
	                        	class="nav-link dropdown-indicator label-1">
	                            <div class="d-flex align-items-center">
	                                <div class="dropdown-indicator-icon"><span class="fa fa-caret-right"></span></div>
	                                <span class="nav-link-icon"><span class="fa-regular fa-gift text-700 fs--1 form-icon"></span></span>
	                                <span class="nav-link-text">Промо</span>
	                            </div>
	                        </a>
	                        <div class="parent-wrapper label-1">
	                            <ul class="nav collapse parent" :class="{'show': this.$route.name.includes('Promotions') || this.$route.name.includes('Promocodes')}">
	                            	<li class="collapsed-nav-item-title d-none">Промо</li>
	                            	<template v-if="$store.state.user.data.permissions.includes('admin') || $store.state.user.data.permissions.includes('admin.promotions')">
		                            	<li class="nav-item">
		                                    <router-link  
		                                    	class="nav-link"
		                                    	:to="{name: 'PromotionsItems'}"
		                                    	:class="{'active': ['PromotionsItems', 'PromotionsItem', 'PromotionsCreate'].includes(this.$route.name)}">
		                                        <div class="d-flex align-items-center"><span class="nav-link-text">Промо акции</span></div>
		                                    </router-link>
		                                </li>
		                                <li class="nav-item">
		                                    <router-link  
		                                    	class="nav-link"
		                                    	:to="{name: 'PromotionsTemplatesItems'}"
		                                    	:class="{'active': ['PromotionsTemplatesItems', 'PromotionsTemplatesItem', 'PromotionsTemplatesCreate'].includes(this.$route.name)}">
		                                        <div class="d-flex align-items-center"><span class="nav-link-text">Шаблоны</span></div>
		                                    </router-link>
		                                </li>
	                                </template>

	                                <li class="nav-item" v-if="$store.state.user.data.permissions.includes('admin') || $store.state.user.data.permissions.includes('admin.promocodes')">
						                <a 
						                	:aria-expanded="this.$route.name.includes('Promocodes')" class="nav-link dropdown-indicator collapsed">
						                    <div class="d-flex align-items-center">
						                        <div class="dropdown-indicator-icon">
						                            <span class="fas fa-caret-right"></span>
						                        </div>
						                        <span class="nav-link-text">Промокоды</span>
						                    </div>
						                </a>
						                <div class="parent-wrapper">
						                    <ul 
						                    	:class="{'show': this.$route.name.includes('Promocodes')}" class="nav parent collapse">
						                        <li class="nav-item">
				                                    <router-link  
				                                    	class="nav-link"
				                                    	:to="{name: 'PromocodesItems'}"
				                                    	:class="{'active': ['PromocodesItems', 'PromocodesItem', 'PromocodesCreate'].includes(this.$route.name)}">
				                                        <div class="d-flex align-items-center"><span class="nav-link-text">Промокоды</span></div>
				                                    </router-link>
				                                </li>
						                        <li class="nav-item">
				                                    <router-link  
				                                    	class="nav-link"
				                                    	:to="{name: 'PromocodesAnalytics'}"
				                                    	:class="{'active': ['PromocodesAnalytics'].includes(this.$route.name)}">
				                                        <div class="d-flex align-items-center"><span class="nav-link-text">Аналитика</span></div>
				                                    </router-link>
				                                </li>
						                    </ul>
						                </div>
						            </li>
						            <!--
	                                <template v-if="$store.state.user.data.permissions.includes('admin') || $store.state.user.data.permissions.includes('admin.promocodes')">
		                                <li class="nav-item">
		                                    <router-link  
		                                    	class="nav-link"
		                                    	:to="{name: 'PromocodesItems'}"
		                                    	:class="{'active': ['PromocodesItems', 'PromocodesItem', 'PromocodesCreate'].includes(this.$route.name)}">
		                                        <div class="d-flex align-items-center"><span class="nav-link-text">Промокоды</span></div>
		                                    </router-link>
		                                </li>
		                            </template>
		                        	-->
	                            </ul>
	                        </div>
	                    </div>
	                </li>

	                <li class="nav-item" v-if="$store.state.user.data.permissions.includes('admin') || $store.state.user.data.permissions.includes('admin.games')">
	                    <div class="nav-item-wrapper">
	                        <a 
	                        	:aria-expanded="this.$route.name.includes('Games') ? 'true' : 'false'" 
	                        	role="button" 
	                        	class="nav-link dropdown-indicator label-1">
	                            <div class="d-flex align-items-center">
	                                <div class="dropdown-indicator-icon"><span class="fa fa-caret-right"></span></div>
	                                <span class="nav-link-icon"><span class="fa-regular fa-slot-machine text-700 fs--1 form-icon"></span></span>
	                                <span class="nav-link-text">Игры</span>
	                            </div>
	                        </a>
	                        <div class="parent-wrapper label-1">
	                            <ul class="nav collapse parent" :class="{'show': this.$route.name.includes('Games')}">
	                            	<li class="collapsed-nav-item-title d-none">Игры</li>
	                                <li class="nav-item">
	                                	<router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'GamesItems'}"
	                                    	:class="{'active': ['GamesItems', 'GamesItem'].includes(this.$route.name)}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">Список</span></div>
	                                    </router-link>
	                                </li>
	                                <li class="nav-item">
	                                	<router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'GamesCategories'}"
	                                    	:class="{'active': ['GamesCategories'].includes(this.$route.name)}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">Категории</span></div>
	                                    </router-link>
	                                </li>
	                                <li class="nav-item">
	                                	<router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'GamesProviders'}"
	                                    	:class="{'active': ['GamesProviders'].includes(this.$route.name)}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">Провайдеры</span></div>
	                                    </router-link>
	                                </li>
	                                <li class="nav-item">
	                                	<router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'GamesAnalytics'}"
	                                    	:class="{'active': ['GamesAnalytics'].includes(this.$route.name)}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">Аналитика</span></div>
	                                    </router-link>
	                                </li>
	                                <li class="nav-item">
	                                	<router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'GamesLogs'}"
	                                    	:class="{'active': ['GamesLogs'].includes(this.$route.name)}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">Логи игр</span></div>
	                                    </router-link>
	                                </li>
	                            </ul>
	                        </div>
	                    </div>
	                    <hr/>
	                </li>

	                <li class="nav-item" v-if="$store.state.user.data.permissions.includes('admin') || $store.state.user.data.permissions.includes('admin.payments')">
	                    <div class="nav-item-wrapper">
	                        <a 
	                        	:aria-expanded="this.$route.name.includes('Payments') ? 'true' : 'false'"
	                        	role="button" 
	                        	class="nav-link dropdown-indicator label-1">
	                            <div class="d-flex align-items-center">
	                                <div class="dropdown-indicator-icon"><span class="fa fa-caret-right"></span></div>
	                                <span class="nav-link-icon"><span class="fa-regular fa-cash-register text-700 fs--1 form-icon"></span></span>
	                                <span class="nav-link-text">
	                                	Бухгалтерия
	                                	<span class="badge badge-casino badge-casino-warning px-1" v-if="$store.state.notifications.withdrawal">
	                                		{{ $store.state.notifications.withdrawal }}
	                                	</span>
	                                </span>
	                            </div>
	                        </a>
	                        <div class="parent-wrapper label-1">
	                            <ul class="nav collapse parent" :class="{'show': this.$route.name.includes('Payments')}">
	                            	<li class="collapsed-nav-item-title d-none">
	                            		Бухгалтерия
	                            		<span class="badge badge-casino badge-casino-warning px-1" v-if="$store.state.notifications.withdrawal">
	                            			{{ $store.state.notifications.withdrawal }}
	                            		</span>
	                            	</li>
	                                <li class="nav-item">
	                                	<router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'PaymentsItems', params: {type: 'deposit'}}"
	                                    	:class="{'active': ['PaymentsItems'].includes(this.$route.name) && this.$route.params.type == 'deposit'}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">Депозиты</span></div>
	                                    </router-link>
	                                </li>
	                                <li class="nav-item">
	                                	<router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'PaymentsItems', params: {type: 'withdrawal'}}"
	                                    	:class="{'active': ['PaymentsItems'].includes(this.$route.name) && this.$route.params.type == 'withdrawal'}">
	                                        <div class="d-flex align-items-center">
	                                        	<span class="nav-link-text">
	                                        		Выплаты
	                                        		<span class="badge badge-casino badge-casino-warning px-1" v-if="$store.state.notifications.withdrawal">
	                                        			{{ $store.state.notifications.withdrawal }}
	                                        		</span>
	                                        	</span>
	                                        </div>
	                                    </router-link>
	                                </li>
	                                <li class="nav-item">
	                                	<router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'PaymentsAnalytics'}"
	                                    	:class="{'active': ['PaymentsAnalytics'].includes(this.$route.name)}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">Аналитика</span></div>
	                                    </router-link>
	                                </li>
	                            </ul>
	                        </div>
	                    </div>
	                </li>


	                <div class="nav-item-wrapper" v-if="$store.state.user.data.permissions.includes('admin') || $store.state.user.data.permissions.includes('admin.merchants')">
					    <router-link 
					    	class="nav-link label-1" 
					    	:to="{name: 'MerchantsItems'}" 
					    	:class="{'active': ['MerchantsItems', 'MerchantsCreate', 'MerchantsItem'].includes(this.$route.name)}">
					        <div class="d-flex align-items-center">
					            <span class="nav-link-icon"><span class="fa-regular fa-square-dollar text-700 fs--1 form-icon"></span></span>
					            <span class="nav-link-text-wrapper"><span class="nav-link-text">Мерчанты</span></span>
					        </div>
					    </router-link>
					</div>

					<div class="nav-item-wrapper" v-if="$store.state.user.data.permissions.includes('admin') || $store.state.user.data.permissions.includes('admin.paysystems')">
					    <router-link 
					    	class="nav-link label-1" 
					    	:to="{name: 'PaysystemsItems'}" 
					    	:class="{'active': ['PaysystemsItems', 'PaysystemsItem', 'PaysystemsCreate'].includes(this.$route.name)}">
					        <div class="d-flex align-items-center">
					            <span class="nav-link-icon"><span class="fa-regular fa-building-columns text-700 fs--1 form-icon"></span></span>
					            <span class="nav-link-text-wrapper"><span class="nav-link-text">Платежные системы</span></span>
					        </div>
					    </router-link>

					    <hr/>
					</div>

	                <li class="nav-item" v-if="$store.state.user.data.permissions.includes('admin') || $store.state.user.data.permissions.includes('admin.messages')">
	                    <div class="nav-item-wrapper">
	                        <a 
	                        	:aria-expanded="this.$route.name.includes('Messages') ? 'true' : 'false'"
	                        	role="button" 
	                        	class="nav-link dropdown-indicator label-1">
	                            <div class="d-flex align-items-center">
	                                <div class="dropdown-indicator-icon"><span class="fa fa-caret-right"></span></div>
	                                <span class="nav-link-icon"><span class="fa-regular fa-messages text-700 fs--1 form-icon"></span></span>
	                                <span class="nav-link-text">Сообщения</span>
	                            </div>
	                        </a>
	                        <div class="parent-wrapper label-1">
	                            <ul class="nav collapse parent" :class="{'show': this.$route.name.includes('Messages')}">
	                            	<li class="collapsed-nav-item-title d-none">Сообщения</li>
	                                <li class="nav-item">
	                                	<router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'MessagesItems', params: {type: 'start'}}"
	                                    	:class="{'active': ['MessagesItems'].includes(this.$route.name) && this.$route.params.type == 'start'}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">Приветственные</span></div>
	                                    </router-link>
	                                </li>
	                                <li class="nav-item">
	                                	<router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'MessagesItems', params: {type: 'regular'}}"
	                                    	:class="{'active': ['MessagesItems'].includes(this.$route.name) && this.$route.params.type == 'regular'}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">История</span></div>
	                                    </router-link>
	                                </li>
	                            </ul>
	                        </div>
	                    </div>

	                    <div class="nav-item-wrapper" v-if="$store.state.user.data.permissions.includes('admin') || $store.state.user.data.permissions.includes('admin.banners')">
						    <router-link 
						    	class="nav-link label-1" 
						    	:to="{name: 'BannersItems'}" 
						    	:class="{'active': ['BannersItems', 'BannersItem', 'BannersCreate'].includes(this.$route.name)}">
						        <div class="d-flex align-items-center">
						            <span class="nav-link-icon"><span class="fa-regular fa-images text-700 fs--1 form-icon"></span></span>
						            <span class="nav-link-text-wrapper"><span class="nav-link-text">Баннеры</span></span>
						        </div>
						    </router-link>
						</div>

	                    <hr/>
	                </li>


					<li class="nav-item" v-if="$store.state.user.data.permissions.includes('admin') || $store.state.user.data.permissions.includes('admin.settings')">
	                    <div class="nav-item-wrapper">
	                        <a 
	                        	:aria-expanded="this.$route.name.includes('Settings') ? 'true' : 'false'"
	                        	role="button" 
	                        	class="nav-link dropdown-indicator label-1">
	                            <div class="d-flex align-items-center">
	                                <div class="dropdown-indicator-icon"><span class="fa fa-caret-right"></span></div>
	                                <span class="nav-link-icon"><span class="fa-regular fa-gear text-700 fs--1 form-icon"></span></span>
	                                <span class="nav-link-text">Настройки</span>
	                            </div>
	                        </a>
	                        <div class="parent-wrapper label-1">
	                            <ul class="nav collapse parent" :class="{'show': this.$route.name.includes('Settings')}">
	                            	<li class="collapsed-nav-item-title d-none">Настройки</li>

	                                <li class="nav-item">
	                                	<router-link  
	                                    	class="nav-link"
	                                    	:to="{name: 'SettingsMirrors'}"
	                                    	:class="{'active': ['SettingsMirrors'].includes(this.$route.name)}">
	                                        <div class="d-flex align-items-center"><span class="nav-link-text">Зеркальные домены</span></div>
	                                    </router-link>
	                                </li>
	                            </ul>
	                        </div>
	                    </div>
	                </li>

	                <div class="nav-item-wrapper">
	                	<hr/>

					    <a class="nav-link label-1" @click="logout">
					        <div class="d-flex align-items-center">
					            <span class="nav-link-icon"><span class="fa-regular fa-right-from-bracket text-700 fs--1 form-icon"></span></span>
					            <span class="nav-link-text-wrapper"><span class="nav-link-text">Выход</span></span>
					        </div>
					    </a>
					</div>

	            </ul>
	        </div>
	    </div>
	    <div class="navbar-vertical-footer">
	        <button class="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 text-start white-space-nowrap">
	            <span class="fa-regular fa-arrow-left-long-to-line text-700 fs--1 form-icon"></span>
	            <span class="fa-regular fa-arrow-right-long-to-line text-700 fs--1 form-icon"></span>
	            <span class="navbar-vertical-footer-text ms-2">Свернутый вид</span>
	        </button>
	    </div>
	</nav>
</template>

<script>
export default {
	data () {
        return {
        	
        }
    },
    methods: {
        logout() {
            localStorage.removeItem('token')
                            
            this.$axios.defaults.headers.common.Authorization = null

            this.$store.state.user.auth = false
        },
        getWithdrawalCount() {
            this.$axios.post('/payments/withdrawal/waiting/count')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.setNotificationWithdrawal(response.data.count)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        },
        getWithdrawalKyc() {
            this.$axios.post('/users/kyc/waiting/count')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.setNotificationKyc(response.data.count)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        }
    },
    mounted () {
    	if(this.$store.state.user.data.permissions.includes('admin') || this.$store.state.user.data.permissions.includes('admin.payments')) {
    		this.getWithdrawalCount()
    	}
    	if(this.$store.state.user.data.permissions.includes('admin') || this.$store.state.user.data.permissions.includes('admin.users')) {
    		this.getWithdrawalKyc()
    	}

    	let nav_links = document.querySelectorAll('.nav-link')
    	for (var el of nav_links) {
	    	el.addEventListener('click', function(e){
	    		let aria_expanded_val = this.getAttribute('aria-expanded')
	    		if(aria_expanded_val == 'true') {
	    			this.setAttribute('aria-expanded', false)
	    		} else {
	    			this.setAttribute('aria-expanded', true)
	    		}
	    		this.classList.toggle('collapsed')
	    		if(this.nextSibling) {
	    			if(this.nextSibling.querySelector('ul.nav')) {
	    				this.nextSibling.querySelector('ul.nav').classList.toggle('show')
	    			}
	    		}
	    	})
    	}

    	document.querySelector('.navbar-vertical-toggle').addEventListener('click', function(e){
    		document.documentElement.classList.toggle('navbar-vertical-collapsed')
    	})
    }
}
</script>